// Import Angular stuff. Angular CommonModule and RouterModule is part of CoreModule imported from the Core library
// below, so no need to import them separately.

import { NgModule, APP_INITIALIZER } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

// Import Angular Material components

import { Breakpoints } from '@angular/cdk/layout';

// Import RxJs stuff

import { of } from 'rxjs';

import { environment } from '../environments/environment';

// Import core, application wide functionality.

import { CoreModule, RootComponent, UserService, ErrorInterceptor, PwaService, PWA_CONFIG } from '@public/core';

import { LayoutModule } from '@public/core/ui/layout';
import { ThemeModule } from '@public/core/ui/theme';

import { Toolbar } from '@public/core/ui/toolbar';
import { Sidenav, SIDENAV_CONFIG } from '@public/core/ui/sidenav';

// Import platform functionality.

import { SharedModule } from '@platform/shared';
import {
  AuthenticationModule,
  AuthorizationInterceptor,
  AuthenticationConfig,
  FacebookAuthenticationConfig,
  GoogleAuthenticationConfig,
  GoogleAuthenticationProvider,
  NativeAuthenticationConfig,
  NativeAuthenticationProvider,
  LoginDialogConfig,
} from '@platform/shared/authentication';

// Import common functionality

import { CommonModule, MushindoUserService, FeatureConfig } from '@mushindo/common';

// Import root routing definitions

import { RootRouting } from './root.routing';

// Import components which are eagerly loaded by the app

import {
  SidenavContentComponent,
  ToolbarContentComponent,
  HomeComponent,
  AboutDialogComponent,
  ChangePasswordDialogComponent,
  CookieDialogComponent,
  PrivacyPolicyComponent,
  ProfileDialogComponent,
  TermsOfUseComponent,
} from './components';

import { QuillModule } from 'ngx-quill';

// Setup external authentication configuration. Replace the app ids below with your own as needed.

const nativeAuthenticationConfig: NativeAuthenticationConfig = {
  clientId: 'api',
  clientSecret: 'secret',
};

const facebookAuthenticationConfig: FacebookAuthenticationConfig = {
  clientId: '1338156956326798',
};

const googleAuthenticationConfig: GoogleAuthenticationConfig = {
  clientId: '193152156134-cke89djemn89ov284b8sas0eibeg18ii.apps.googleusercontent.com',
  oneTapEnabled: false,
};

const authenticationConfig: AuthenticationConfig = {
  autoLogin: false,
  providers: [NativeAuthenticationProvider, GoogleAuthenticationProvider],
  messages: {
    loginSuccess: name => {
      return `Du er blevet logget ind som ${name}.`;
    },
    loginFailure: 'Der opstod en fejl i forbindelse med dit forsøg på at logge ind.',
    logoutSuccess: 'Du er blevet logget ud.',
    logoutFailure: 'Der opstod en fejl i forbindelse med dit forsøg på at logge ud.',
  },
};

const loginDialogConfig: LoginDialogConfig = {
  labels: {
    title: 'Log ind med',
    username: 'Brugernavn',
    password: 'Adgangskode',
    alternative: 'eller',
    login: 'Log ind',
    cancel: 'Annullér',
  },
};

// Set feature toggles

const featureConfig: FeatureConfig = {
  legacyMode: true,
};

// Disable animations if animations are not supported by the browser or if we are running on old
// version of iOS.

const disableAnimations =
  !('animate' in document.documentElement) ||
  (navigator && /iPhone OS (8|9|10|11|12|13)_|iPad OS/.test(navigator.userAgent));

export function initSidenav(sidenav: Sidenav) {
  return () => {
    sidenav.content = SidenavContentComponent;
    return of(true).toPromise();
  };
}

export function initToolbar(toolbar: Toolbar) {
  return () => {
    toolbar.content = ToolbarContentComponent;
    return of(true).toPromise();
  };
}

export function initPwaService() {
  return () => {
    return of(true).toPromise();
  };
}

@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CoreModule,
    LayoutModule,
    ThemeModule,

    SharedModule,
    AuthenticationModule,

    CommonModule,

    RootRouting,

    QuillModule.forRoot(),
  ],
  declarations: [
    HomeComponent,
    SidenavContentComponent,
    ToolbarContentComponent,
    AboutDialogComponent,
    ChangePasswordDialogComponent,
    CookieDialogComponent,
    PrivacyPolicyComponent,
    ProfileDialogComponent,
    TermsOfUseComponent,
  ],
  providers: [
    {
      provide: 'AuthenticationConfig',
      useValue: authenticationConfig as AuthenticationConfig,
    },
    { provide: 'NativeAuthenticationConfig', useValue: nativeAuthenticationConfig as NativeAuthenticationConfig },
    { provide: 'FacebookAuthenticationConfig', useValue: facebookAuthenticationConfig as FacebookAuthenticationConfig },
    { provide: 'GoogleAuthenticationConfig', useValue: googleAuthenticationConfig as GoogleAuthenticationConfig },

    { provide: LoginDialogConfig, useValue: loginDialogConfig },

    {
      provide: FeatureConfig,
      useValue: featureConfig,
    },
    {
      provide: UserService,
      useClass: MushindoUserService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, deps: [Sidenav], useFactory: initSidenav, multi: true },
    { provide: APP_INITIALIZER, deps: [Toolbar], useFactory: initToolbar, multi: true },
    {
      provide: APP_INITIALIZER,
      deps: [PwaService],
      useFactory: initPwaService,
      multi: true,
    },
    {
      provide: PWA_CONFIG,
      useValue: {
        installPrompt: undefined,
        updatePrompt: {
          promptText: `En ny version af ${window.location.hostname} er tilgængelig.`,
          buttonText: 'Installér og genindlæs',
        },
      },
    },
    { provide: SIDENAV_CONFIG, useValue: [Breakpoints.Handset, Breakpoints.TabletPortrait] },
  ],
  bootstrap: [RootComponent],
})
export class RootModule {}
