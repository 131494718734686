<mat-nav-list>

  <!-- Karate -->

  <a mat-list-item (click)="toggle('karate')" routerLink="/karate" routerLinkActive="selected">
    <div matListItemTitle>Karate</div>
    <div matListItemMeta>
      <mat-icon *ngIf="expanded === 'karate'" class="material-icons">expand_more</mat-icon>
      <mat-icon *ngIf="expanded !== 'karate'" class="material-icons">chevron_right</mat-icon>
    </div>
  </a>

  <mat-nav-list mat-list-item *ngIf="expanded === 'karate'">
    <a mat-list-item routerLink="/karate/kata" routerLinkActive="selected">Kata</a>
    <a mat-list-item routerLink="/karate/kihon" routerLinkActive="selected">Kihon</a>
    <a mat-list-item routerLink="/karate/kumete" routerLinkActive="selected">Kumete</a>
    <a *ngIf="userIsMember" mat-list-item routerLink="/karate/pensum" routerLinkActive="selected">Pensum</a>
  </mat-nav-list>

  <!-- Kobudo -->

  <a mat-list-item (click)="toggle('kobudo')" routerLink="/kobudo" routerLinkActive="selected">
    <div matListItemTitle>Kobudō</div>
    <div matListItemMeta>
      <mat-icon *ngIf="expanded === 'kobudo'" class="material-icons">expand_more</mat-icon>
      <mat-icon *ngIf="expanded !== 'kobudo'" class="material-icons">chevron_right</mat-icon>
    </div>
  </a>

  <mat-nav-list mat-list-item *ngIf="expanded === 'kobudo'">
    <a mat-list-item routerLink="/kobudo/bo" routerLinkActive="selected">Bō</a>
    <a mat-list-item routerLink="/kobudo/hanbo" routerLinkActive="selected">Hanbō</a>
    <a mat-list-item routerLink="/kobudo/sai" routerLinkActive="selected">Sai</a>
<!--
    <a mat-list-item routerLink="/kobudo/bokken" routerLinkActive="selected">Bokken</a>
-->
  </mat-nav-list>

  <!-- About Mushindo -->

  <a mat-list-item (click)="toggle('klubben')" routerLink="/klubben" routerLinkActive="selected">
    <div matListItemTitle>Klubben</div>
    <div matListItemMeta>
      <mat-icon *ngIf="expanded === 'klubben'" class="material-icons">expand_more</mat-icon>
      <mat-icon *ngIf="expanded !== 'klubben'" class="material-icons">chevron_right</mat-icon>
    </div>
  </a>

  <mat-nav-list mat-list-item *ngIf="expanded === 'klubben'">
    <a mat-list-item routerLink="/klubben/træningen" routerLinkActive="selected">Træningen</a>
    <a mat-list-item routerLink="/klubben/graduering" routerLinkActive="selected">Graduering</a>
    <a mat-list-item routerLink="/klubben/instruktører" routerLinkActive="selected">Instruktører</a>
    <a mat-list-item routerLink="/klubben/tider-og-priser" routerLinkActive="selected">Tider og priser</a>
    <a mat-list-item routerLink="/klubben/indmeldelse" routerLinkActive="selected">Indmeldelse</a>
    <a mat-list-item routerLink="/klubben/betaling" routerLinkActive="selected">Betaling</a>
    <a mat-list-item routerLink="/klubben/reishiki" routerLinkActive="selected">Reishiki</a>
    <a mat-list-item routerLink="/klubben/samværspolitik" routerLinkActive="selected">Samværspolitik</a>
    <a mat-list-item routerLink="/klubben/bestyrelse" routerLinkActive="selected">Bestyrelse</a>
    <a mat-list-item routerLink="/klubben/generalforsamling" routerLinkActive="selected">Generalforsamling</a>
    <a mat-list-item routerLink="/klubben/vedtægter" routerLinkActive="selected">Vedtægter</a>
  </mat-nav-list>
  <!--
  <a mat-list-item routerLink="/concepts" routerLinkActive="selected">Ord og begreber</a>
  <a mat-list-item routerLink="/counting" routerLinkActive="selected">Tal på japansk</a>
-->
  <!-- Instructors -->

  <a *ngIf="userIsInstructor" mat-list-item (click)="toggle('instructor'); (false)">
    <div matListItemTitle>For trænere</div>
    <div matListItemMeta>
      <mat-icon *ngIf="expanded === 'instructor'" class="material-icons">expand_more</mat-icon>
      <mat-icon *ngIf="expanded !== 'instructor'" class="material-icons">chevron_right</mat-icon>
    </div>
  </a>

  <mat-nav-list *ngIf="userIsInstructor && expanded === 'instructor'" mat-list-item>
    <a mat-list-item routerLink="/trænere/medlemmer" routerLinkActive="selected">Medlemmer</a>
    <a mat-list-item routerLink="/trænere/træning" routerLinkActive="selected">Træning</a>
    <a mat-list-item routerLink="/trænere/graduering" routerLinkActive="selected">Graduering</a>
  </mat-nav-list>

  <!-- <a mat-list-item routerLink="/klubben/indmeldelse" routerLinkActive="selected">Bliv medlem</a> -->
</mat-nav-list>
