<h1 mat-dialog-title>{{ currentUser.realname }}</h1>
<div mat-dialog-content>
  <img *ngIf="currentUser.photoUrl" class="photo" src="{{ currentUser.photoUrl }}" />

  <form [formGroup]="form" (submit)="submit()">
    <mat-form-field class="full-width">
      <mat-label>Brugernavn</mat-label>
      <input matInput readonly value="{{ currentUser.username }}" />
      <button mat-icon-button matSuffix (click)="changePassword()">
        <mat-icon>password</mat-icon>
      </button> 
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>E-mail</mat-label>
      <input matInput readonly value="{{ currentMember.email }}" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Telefon</mat-label>
      <input matInput readonly value="{{ currentMember.phone }}" />
    </mat-form-field>

    <mat-form-field *ngIf="currentUser.roles" class="full-width">
      <mat-label>Rolle(r)</mat-label>
      <input matInput readonly value="{{ roleNames }}" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Niveau</mat-label>
      <input matInput readonly value="{{ currentMember.level | levelToText }}" />
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button cdkFocusInitial type="submit" color="primary" (click)="submit()">OK</button>
  <button mat-raised-button  type="button" (click)="cancel()">Annullér</button>
</div>
