// Import Angular stuff

import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocalStorage } from '@public/core';

@Component({
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
})
export class CookieDialogComponent {
  constructor(
    private localStorage: LocalStorage,
    private dialog: MatDialogRef<CookieDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ok() {
    this.localStorage.set('cookiesAccepted', new Date().toISOString());
    this.dialog.close();
  }
}
