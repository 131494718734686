<h3 mat-dialog-title>Acceptér brug af cookies</h3>

<div mat-dialog-content>
  Vi bruger cookies for at dette websted til at virke, f.eks. i forbindelse med login. Hvis du fortsætter accepterer du
  samtidigt vores bruge af cookies. Du kan finde yderligere information i vores
  <a routerLink="/privacy-policy" routerLinkActive="active">privatlivspolitik</a> og
  <a routerLink="/terms-of-use" routerLinkActive="active">betingelser for brug</a>.
</div>

<div mat-dialog-actions>
  <button mat-raised-button type="submit" color="primary" (click)="ok()">OK</button>
</div>
