// Import Angular stuff

import { Component } from '@angular/core';

// Import components and services used by the TermsOfUseComponent

import { WindowService } from '@public/core';

@Component({
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent {
  constructor(public windowService: WindowService) {}
}
