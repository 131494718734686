// Import Angular stuff

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Import RxJS stuff

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User } from '@public/core';

import { AuthenticationService } from '@platform/shared/authentication';

export const passwordValidatorExpression: RegExp =
/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;

@Component({
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
  protected currentUser: any;

  protected form!: FormGroup;

  protected oldPassword = new FormControl<string>('', Validators.required);
  protected newPassword = new FormControl<string>('', [Validators.required, Validators.pattern(passwordValidatorExpression)]);

  protected showOldPassword = false;
  protected showNewPassword = false;

  private unsubscriber = new Subject<void>();

  constructor(
    protected authenticationService: AuthenticationService,
    private dialog: MatDialogRef<ChangePasswordDialogComponent>,
    private builder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentUser = authenticationService.getCurrentUser();
  }

  public ngOnInit() {
    this.authenticationService.authenticationStateChange.pipe(takeUntil(this.unsubscriber)).subscribe((user?: User) => {
      this.currentUser = user;
    });
    this.form = this.builder.group({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
    });
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  protected submit() {
    this.dialog.close();
  }

  protected cancel() {
    this.dialog.close();
  }
}
