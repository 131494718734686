<span matRipple routerLink="/">
<!--
  <img *ngIf="!isSmall" class="logo" src="/assets/graphics/logo/black-64.png">
-->
  <span>{{ titleService.getTitle() }}</span>
</span>

<!-- <theme-toggle></theme-toggle> -->

<button mat-icon-button *ngIf="currentUser" (click)="profile()">
  <mat-icon>person</mat-icon>
</button>

<button mat-icon-button *ngIf="!currentUser" (click)="login()">
  <mat-icon>login</mat-icon>
</button>

<button mat-icon-button *ngIf="currentUser" (click)="logout()">
  <mat-icon>logout</mat-icon>
</button>

<button mat-icon-button [matMenuTriggerFor]="moreMenu">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #moreMenu="matMenu">
  <button mat-menu-item routerLink="/privacy-policy" routerLinkActive="active">Privatelivspolitik</button>
  <button mat-menu-item routerLink="/terms-of-use" routerLinkActive="active">Betingelser for brug</button>

  <mat-divider></mat-divider>

  <button mat-menu-item (click)="about()">Om websitet</button>
</mat-menu>
