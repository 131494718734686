<h1 mat-dialog-title>Ændring af adgangskode</h1>

<div mat-dialog-content>
  <form [formGroup]="form" (submit)="submit()">
    <mat-form-field class="full-width">
      <mat-label>Brugernavn</mat-label>
      <input matInput readonly value="{{ currentUser.username }}" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Nuværende adgangskode</mat-label>
      <input
        matInput
        cdkFocusInitial
        placeholder="Adgangskode"
        [type]="showOldPassword ? 'text' : 'password'"
        formControlName="oldPassword"
      />
      <mat-icon matSuffix (click)="showOldPassword = !showOldPassword">{{
        showOldPassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Ny adgangskode</mat-label>
      <input
        matInput
        placeholder="Adgangskode"
        [type]="showNewPassword ? 'text' : 'password'"
        formControlName="newPassword"
      />
      <mat-icon matSuffix (click)="showNewPassword = !showNewPassword">{{
        showNewPassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
      <mat-hint align="end">Minimum ét stort bogstav, ét lille, ét ciffer og ét specialtegn, i alt minimum 8 tegn.</mat-hint>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button type="submit" color="primary" [disabled]="form.invalid" (click)="submit()">OK</button>
  <button mat-raised-button type="button" (click)="cancel()">Annullér</button>
</div>
