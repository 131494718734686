// Import Angular stuff

import { Component, OnInit, Input } from '@angular/core';

// Import services used by the SidenavContentComponent
import {User} from '@platform/shared';
import { AuthenticationService } from '@platform/shared/authentication';

import { FeatureConfig } from '@mushindo/common';

@Component({
  templateUrl: './sidenav-content.component.html',
  styleUrls: ['./sidenav-content.component.scss'],
})
export class SidenavContentComponent implements OnInit {
  @Input()
  public expanded?: string;

  public currentUser?: User;

  constructor(protected featureConfig: FeatureConfig, private authenticationService: AuthenticationService) {}

  get userIsMember(): boolean {
    return !!this.currentUser;
  }

  get userIsInstructor(): boolean {
    const user: any = this.currentUser;
    return user ? user.roles.find((item:any) => item.code === 'INSTRUCTOR') : false;
  }

  public ngOnInit() {
    this.currentUser = this.authenticationService.getCurrentUser();

    this.authenticationService.authenticationStateChange.subscribe({
      next: (user?: User) => {
        this.currentUser = user;
      }
    });
  }

  public toggle(submenu: string): boolean {
    this.expanded !== submenu ? (this.expanded = submenu) : (this.expanded = undefined);

    // Always return false in order to stop propagation of the click that would otherwise cause the sidenav to close.

    return false;
  }
}
