import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, SkipSelf, Component, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import * as i3 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i4 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject } from 'rxjs';
import * as i1 from '@public/core';
function ThemeToggleComponent_button_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 1);
    i0.ɵɵlistener("click", function ThemeToggleComponent_button_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.toggleTheme());
    });
    i0.ɵɵelementStart(1, "mat-icon");
    i0.ɵɵtext(2, "light_mode");
    i0.ɵɵelementEnd()();
  }
}
function ThemeToggleComponent_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 1);
    i0.ɵɵlistener("click", function ThemeToggleComponent_button_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.toggleTheme());
    });
    i0.ɵɵelementStart(1, "mat-icon");
    i0.ɵɵtext(2, "dark_mode");
    i0.ɵɵelementEnd()();
  }
}
const DEFAULT_THEME = new InjectionToken('default_theme');
const THEME_KEY = 'theme';
class ThemeService {
  storage;
  instance;
  themeChangeSource = new BehaviorSubject(undefined);
  get theme() {
    return this.themeChangeSource.value;
  }
  set theme(value) {
    const htmlRoot = document.getElementsByTagName('html')[0];
    if (htmlRoot) {
      // Remove previously set theme class.
      if (!!this.storage.get(THEME_KEY) && htmlRoot.classList.contains(`${this.storage.get(THEME_KEY)}-theme`)) {
        htmlRoot.classList.remove(`${this.storage.get(THEME_KEY)}-theme`);
      }
      // Set theme class on document's HTML element unless the theme is "default" or empty .
      if (!!value && value !== 'default') {
        htmlRoot.classList.add(`${value}-theme`);
      }
    }
    // Store applied theme in local storage for later use and emit change event.
    this.storage.set(THEME_KEY, value);
    this.themeChangeSource.next(value);
  }
  get themeChange() {
    return this.themeChangeSource.asObservable();
  }
  constructor(storage, defaultTheme, instance) {
    this.storage = storage;
    this.instance = instance;
    if (instance) {
      throw new Error(`${this.constructor.name} already instantiated. Provide it only in the root module.`);
    }
    if (!this.storage.get(THEME_KEY)) {
      this.storage.set(THEME_KEY, defaultTheme ? defaultTheme : 'default');
    }
    this.theme = this.storage.get(THEME_KEY);
  }
  static ɵfac = function ThemeService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ThemeService)(i0.ɵɵinject(i1.LocalStorage), i0.ɵɵinject(DEFAULT_THEME, 8), i0.ɵɵinject(ThemeService, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ThemeService,
    factory: ThemeService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.LocalStorage
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [DEFAULT_THEME]
    }]
  }, {
    type: ThemeService,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

// Import Angular stuff
class ThemeToggleComponent {
  themeService;
  darkMode = false;
  constructor(themeService) {
    this.themeService = themeService;
    this.darkMode = this.themeService.theme === 'dark';
    themeService.themeChange.subscribe(value => this.darkMode = value === 'dark');
  }
  toggleTheme() {
    this.themeService.theme = this.themeService.theme === 'default' ? 'dark' : 'default';
  }
  static ɵfac = function ThemeToggleComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ThemeToggleComponent)(i0.ɵɵdirectiveInject(ThemeService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ThemeToggleComponent,
    selectors: [["theme-toggle"]],
    decls: 2,
    vars: 2,
    consts: [["mat-icon-button", "", 3, "click", 4, "ngIf"], ["mat-icon-button", "", 3, "click"]],
    template: function ThemeToggleComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, ThemeToggleComponent_button_0_Template, 3, 0, "button", 0)(1, ThemeToggleComponent_button_1_Template, 3, 0, "button", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.darkMode);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.darkMode);
      }
    },
    dependencies: [i2.NgIf, i3.MatIconButton, i4.MatIcon],
    styles: [".mat-icon[_ngcontent-%COMP%]{cursor:pointer}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeToggleComponent, [{
    type: Component,
    args: [{
      selector: 'theme-toggle',
      template: "<button mat-icon-button *ngIf=\"darkMode\" (click)=\"toggleTheme()\">\r\n  <mat-icon>light_mode</mat-icon>\r\n</button>\r\n<button mat-icon-button *ngIf=\"!darkMode\" (click)=\"toggleTheme()\">\r\n  <mat-icon>dark_mode</mat-icon>\r\n</button>",
      styles: [".mat-icon{cursor:pointer}\n"]
    }]
  }], () => [{
    type: ThemeService
  }], null);
})();

// Import Angular, CDK and Marerial stuff
class ThemeModule {
  static ɵfac = function ThemeModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ThemeModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ThemeModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule],
      declarations: [ThemeToggleComponent],
      exports: [ThemeToggleComponent]
    }]
  }], null, null);
})();

/**
 * Public surface API of @public/core/ui/theme
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ThemeModule, ThemeService, ThemeToggleComponent };
