<div class="header">
  <img src="/assets/graphics/logo/white-96.png" width="96px" height="96px" />
  <div>
    <h1 mat-dialog-title>Mushindo</h1>
    <span mat-dialog-subtitle>{{ environment.myVersion }}</span>
  </div>
</div>

<div mat-dialog-content>
  Bygget med <a target="_new" href="http://angular.io">Angular</a> {{ environment.ngVersion }} ved hjælp af
  <a target="_new" href="http://material.angular.io">Angular Material</a> {{ environment.matVersion }}. Programmeret i
  <a target="_new" href="http://typescriptlang.org">TypeScript</a> {{ environment.tsVersion }}.
</div>

<div mat-dialog-actions>
  <button mat-raised-button cdkFocusInitial type="submit" color="primary" (click)="ok()">OK</button>
</div>
