// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import pkg from '../../../../package-lock.json';

export const environment = {
  production: false,
  myVersion: pkg.version,
  ngVersion: pkg.packages['node_modules/@angular/core'].version,
  cdkVersion: pkg.packages['node_modules/@angular/cdk'].version,
  matVersion: pkg.packages['node_modules/@angular/material'].version,
  rxVersion: pkg.packages['node_modules/rxjs'].version,
  tsVersion: pkg.packages['node_modules/typescript'].version,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
