import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Import components referenced by the root router (i.e. compoments which are activated through an eager loaded route).

import { NotFoundComponent } from '@mushindo/common';

import {
  HomeComponent,
  PrivacyPolicyComponent,
  TermsOfUseComponent,
} from './components';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'index.html',
    component: HomeComponent,
  },
  {
    path: 'karate',
    loadChildren: () => import('./features/karate/karate.module').then(m => m.KarateModule),
  },
  {
    path: 'kobudo',
    loadChildren: () => import('./features/kobudo/kobudo.module').then(m => m.KobudoModule),
  },
  {
    path: 'klubben',
    loadChildren: () => import('./features/mushindo/mushindo.module').then(m => m.MushindoModule),
  },
  {
    path: 'medlemmer',
    loadChildren: () => import('./features/medlemmer/medlemmer.module').then(m => m.MedlemmerModule),
  },
  {
    path: 'trænere',
    loadChildren: () => import('./features/trænere/trænere.module').then(m => m.TrænereModule),
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RootRouting {}
