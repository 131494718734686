// Import Angular stuff

import { Component, OnDestroy, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

// Import RxJS stuff

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LocalStorage, ApplicationStorage } from '@public/core';
import { Sidenav, SidenavMode } from '@public/core/ui/sidenav';

import { AuthenticationService } from '@platform/shared/authentication';

import { CookieDialogComponent } from './cookie-dialog.component';


@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  protected currentUser: any;

  protected visited = false;
  protected mode?: SidenavMode;

  private unsubscriber = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly sidenav: Sidenav,
    private readonly authenticationService: AuthenticationService,
    private readonly localStorage: LocalStorage,
    private readonly applicationStorage: ApplicationStorage,
  ) {
    this.currentUser = this.authenticationService.getCurrentUser();
  }

  public ngOnInit() {
    // If use of cookies has not been accepted, show a dialog "forcing" the user to accept in order to proceed.

    if (!this.localStorage.get('cookiesAccepted')) {
      this.dialog.open(CookieDialogComponent, { disableClose: true });
    }

    if (this.applicationStorage.get('previouslyVisited')) {
      this.visited = true;
    } else {
      this.applicationStorage.set('previouslyVisited', true);
    }

    this.authenticationService.authenticationStateChange.pipe(takeUntil(this.unsubscriber)).subscribe(user => {
      this.currentUser = user;
    });

    this.sidenav.modeChange.pipe(takeUntil(this.unsubscriber)).subscribe(mode => {
      this.mode = mode;
    });
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
  
  protected isMobile() {
    const regex = /Android|iPhone/i;
    return regex.test(navigator.userAgent);
  }
}
